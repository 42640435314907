import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const VITE_FUNCTION_BASE_URL = import.meta.env.VITE_FUNCTION_BASE_URL;

export const useUniversity = () => {
  const [university, setUniversity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUniversity = async () => {
      try {
        /*
        development-client.meritcurve.com
        meritcurve-dev.web.app
        demo-candidate.meritcurve.com
        learn-kgisl.microcollege.in
        ace.meritcurve.com
        learn.meritcurve.com
        www.vedic.dev
        learn.logicwhile.com
        learn.thecodenow.in
      */
        const domain =
          import.meta.env.MODE === "development"
            ? "www.vedic.dev"
            : window.location.host;

        const response = await fetch(
          `${VITE_FUNCTION_BASE_URL}/meritcurveClient/university?domain=${domain}`
        );

        if (response.status === 404) {
          throw new Error(
            "University not found. Please check the URL you entered and try again."
          );
        }

        if (!response.ok) {
          throw new Error(`Failed to fetch university: ${response.status}`);
        }

        const data = await response.json();
        setUniversity(data?.university);
      } catch (error) {
        setError(error);
        toast.error(error.message || "Failed to load university configuration");
        console.error("University fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUniversity();
  }, []);

  return { university, loading, error };
};
