import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { lazyWithRetry } from "@/common/utils/lazyWithRetry";
import FallbackLoader from "@/common/components/shared/FallbackLoader";
import Report from "@/pages/report-card";

// Lazy-loaded components
const LoginPage = lazyWithRetry(() => import("@/pages/auth/Login"));
const SignupPage = lazyWithRetry(() => import("@/pages/auth/Signup"));
const ForgotPasswordPage = lazyWithRetry(() =>
  import("@/pages/auth/ForgotPassword")
);
const Onboarding = lazyWithRetry(() => import("@/pages/onboarding"));
const ViewCourse = lazyWithRetry(() =>
  import("@/features/course/view_course/components/ViewCourse")
);

// This function returns an array of route configurations
export const getPublicRoutes = (user, university) =>
  [
    {
      path: "/login",
      element: user ? (
        <Navigate to="/" />
      ) : (
        <LoginPage university={university} />
      ),
    },
    university?.loginConfig.isPublicSignupAllowed && {
      path: "/signup",
      element: user ? (
        <Navigate to="/" />
      ) : (
        <SignupPage university={university} />
      ),
    },
    {
      path: "/forgot-password",
      element: user ? (
        <Navigate to="/" />
      ) : (
        <ForgotPasswordPage university={university} />
      ),
    },
    {
      path: "/welcome",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Onboarding />
        </Suspense>
      ),
    },
    {
      path: "/course/:course_id/:participant_id/*",
      element: <ViewCourse />,
    },
    {
      path: "/report/:participant_id/*",
      element: <Report/>,
    }
  ].filter(Boolean); // Remove falsy values (for conditional routes)
