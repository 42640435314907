import { Progress } from "@/common/components/ui/progress"
import { Avatar, AvatarFallback, AvatarImage } from "@/common/components/ui/avatar"
import { Badge } from "@/common/components/ui/badge"
import { Star, StarHalf, Code, Award, GitBranch, CalendarDays, Clock, BookOpen, GraduationCap } from "lucide-react"
import { useEffect, useState, useMemo } from "react"
import { FaChevronRight } from "react-icons/fa";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";

export default function StudentReport() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [error, setError] = useState(null)
  const [missingParams, setMissingParams] = useState(false); 

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
  
        const searchParams = new URLSearchParams(window.location.search);
        const universityId = searchParams.get("university_id");
        const candidateId = searchParams.get("candidateId");

        if (!universityId || !candidateId) {
          console.warn("Missing required parameters: university_id or candidateId");
          setMissingParams(true);
          return;
        }

        const assessmentIds = searchParams.getAll("assessmentIds");
        const courseIds = searchParams.getAll("courseIds");
  
        const params = new URLSearchParams();
        if (universityId) params.append("university_id", universityId);
        if (candidateId) params.append("candidateId", candidateId);
        if (assessmentIds.length) {
          assessmentIds.forEach(id => params.append("assessmentIds", id));
        }
        if (courseIds.length) {
          courseIds.forEach(id => params.append("courseIds", id));
        }
  
        const url = `${import.meta.env.VITE_SERVERLESS_FUNCTION_BASE_URL}/client/report/details?${params.toString()}`;
  
        const response = await fetch(url);
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const responseData = await response.json();
        setData(responseData);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  // Calculate overall metrics from all courses
  const overallStats = useMemo(() => {
    if (!data?.course_bb_participants) return null;

    const courseParticipants = data.course_bb_participants || [];
    let totalCompletedLessons = 0;
    let totalLessons = 0;
    let totalTimeSpent = 0;
    let activeCourses = 0;
    let completedCourses = 0;

    courseParticipants.forEach((participant) => {
      const course = participant.courses_bb;
      const stats = course.course_content_block_stats || {};

      totalCompletedLessons += stats.completed_mandatory_modules || 0;
      totalLessons += stats.total_mandatory_modules || 0;
      totalTimeSpent += course.time_spent || 0;

      if (course.active) {
        activeCourses++;
      }

      // Consider a course completed if percentage is 100%
      if (stats.percent === 100) {
        completedCourses++;
      }
    });

    const overallPercentage =
      totalLessons > 0
        ? Math.round((totalCompletedLessons / totalLessons) * 100)
        : 0;

    // Format time spent in hours and minutes
    const hours = Math.floor(totalTimeSpent / 3600);
    const minutes = Math.floor((totalTimeSpent % 3600) / 60);

    return {
      totalCompletedLessons,
      totalLessons,
      overallPercentage,
      totalTimeSpent,
      formattedTimeSpent: hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`,
      activeCourses,
      completedCourses,
      totalCourses: courseParticipants.length,
    };
  }, [data]);

  const subjectProgress = useMemo(() => {
    if (!data?.user_sub_topic_wise_overview?.test_attempt_sub_topic_wise_overview) return {};

    return data.user_sub_topic_wise_overview.test_attempt_sub_topic_wise_overview.reduce((acc, item) => {
      const subjectId = item.sub_topic.topic.subject.id;
      const subjectName = item.sub_topic.topic.subject.name;
      const topicId = item.sub_topic.topic.id;
      const topicName = item.sub_topic.topic.name;

      if (!acc[subjectId]) {
        acc[subjectId] = {
          name: subjectName,
          marksObtained: 0,
          maxMarks: 0,
          topics: {},
        };
      }

      if (!acc[subjectId].topics[topicId]) {
        acc[subjectId].topics[topicId] = {
          name: topicName,
          marksObtained: 0,
          maxMarks: 0,
          correct_count: 0,
          questions_attempted_count: 0,
        };
      }

      acc[subjectId].marksObtained += item.marks_obtained;
      acc[subjectId].maxMarks += item.max_marks;
      acc[subjectId].topics[topicId].marksObtained += item.marks_obtained;
      acc[subjectId].topics[topicId].maxMarks += item.max_marks;
      acc[subjectId].topics[topicId].correct_count += item.correct_count;
      acc[subjectId].topics[topicId].questions_attempted_count +=
        item.questions_attempted_count;

      return acc;
    }, {});
  }, [data]);

  function TopicProgress({ subject }) {
    return (
      <div className="container p-6 bg-white rounded-lg">
        <h2 className="mb-4 text-xl font-bold">
          Accuracy of {subject.name} Subject
        </h2>
        {Object.entries(subject.topics).map(([topicId, topic]) => (
          <div key={topicId} className="p-3 mb-4 border">
            <h3 className="text-sm font-medium">{topic.name}</h3>
            <div className="flex items-center justify-between mb-1">
              <span className="text-sm text-gray-500">
                Questions Solved: {topic.questions_attempted_count} | Correctly
                Solved: {topic.correct_count}
              </span>
              <div className="flex w-1/2 gap-2">
                <div className="relative flex items-center justify-between w-3/4 h-4 overflow-hidden bg-gray-200 rounded-full">
                  <div
                    className={`${getProgressColor(
                      topic.marksObtained,
                      topic.maxMarks
                    )} h-4 absolute left-0 top-0`}
                    style={{
                      width: `${
                        topic.maxMarks > 0
                          ? (topic.marksObtained / topic.maxMarks) * 100
                          : 0
                      }%`,
                    }}
                  />
                </div>
                <span className="text-sm font-medium">
                  {topic.maxMarks > 0
                    ? Math.round((topic.marksObtained / topic.maxMarks) * 100)
                    : 0}
                  %
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  function getProgressColor(marksObtained, maxMarks) {
    const percentage = maxMarks > 0 ? (marksObtained / maxMarks) * 100 : 0;
    if (percentage < 50) return "bg-red-500";
    if (percentage < 75) return "bg-yellow-500";
    return "bg-green-500";
  }

  // If still loading, show a loading state
  if (loading) {
    return <div className="container mx-auto py-8 px-6 max-w-[1000px] bg-white">Loading student report...</div>
  }

  // If required parameters are missing, show a message
  if (missingParams) {
    return (
      <div className="container mx-auto py-8 px-6 max-w-[1000px] bg-white">
        <div className="text-center text-red-600 font-bold">
          Missing required parameters: <span className="text-gray-800">university_id</span> or <span className="text-gray-800">candidateId</span>.
        </div>
      </div>
    );
  }

  // If there was an error, show error state
  if (error) {
    return <div className="container mx-auto py-8 px-6 max-w-[1000px] bg-white">Error loading report: {error}</div>
  }

  // If no data was returned
  if (!data) {
    return <div className="container mx-auto py-8 px-6 max-w-[1000px] bg-white">No data available</div>
  }

  // Extract data from the API response
  const student = {
    name: data.fullName || "N/A",
    email: data.email || "N/A",
    batch: data.batch_users?.[0]?.batch?.name || "N/A",
    universityName: data.university.name || "university",
    id: data.id || "N/A",
    avatar: data.profile_picture || "/placeholder.svg?height=100&width=100",
    overview: {
      testAssigned: data.user_assessment_summary?.assigned_test?.aggregate?.count || 0,
      testcompleted: data.user_assessment_summary?.completed_test?.aggregate?.count || 0,
      progress:
        Math.round(
          (data.user_assessment_summary?.completed_test?.aggregate?.count /
            data.user_assessment_summary?.assigned_test?.aggregate?.count) *
            100,
        ) || 0,
        questionsAttempted: data.user_assessment_summary?.test_question_submissions_aggregate?.aggregate?.count || 0,
        // timeSpent : Math.floor((data.user_assessment_summary?.test_question_submissions_aggregate?.aggregate?.sum?.time_taken || 0) / 60) || 0,
        timeSpent :Math.floor(data.user_assessment_summary?.test_question_submissions_aggregate?.aggregate?.sum?.time_taken / 3600) + "h " + Math.floor((data.user_assessment_summary?.test_question_submissions_aggregate?.aggregate?.sum?.time_taken % 3600) / 60) + "m",

    },
  }

  // Extract assignments data from test_participants
  const assessments = data.test_participants?.map((participant) => ({
      id: participant.id,
      name: participant.test?.title || "Unknown Assignment",
      submittedOn: participant.test_attempt?.completed_at
        ? new Date(participant.test_attempt.completed_at).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })
        : "N/A",
      percentage:Math.round(participant.test_attempt?.test_attempt_overview?.percentage * 100) || 0,
      status: participant.test_attempt?.is_completed ? "completed" : "pending",
    })) || []

  // Extract courses data
  const courses =
    data.course_bb_participants?.map((course) => ({
      id: course.courses_bb.id,
      name: course.courses_bb.name,
      total: course.courses_bb.course_content_block_stats?.total_modules || 0,
      completed: course.courses_bb.course_content_block_stats?.completed_modules || 0,
      progress: course.courses_bb.course_content_block_stats?.percent || 0,
    })) || []

  // Platform performance data
  const platformData = {
    leetcode: {
      problemsSolved: data.leetcodeLeaderboard?.[0]?.["All count"] || 0,
      easy: data.leetcodeLeaderboard?.[0]?.["Easy count"] || 0,
      medium: data.leetcodeLeaderboard?.[0]?.["Medium count"] || 0,
      hard: data.leetcodeLeaderboard?.[0]?.["Hard count"] || 0,
      badges: data.leetcodeLeaderboard?.[0]?.badges?.length || 0,
    },
    hackerrank: {
    total_score: Math.round(data.hackerrank_leaderboard?.[0]?.total_score || 0),
      stars: calculateHackerRankStars(data.hackerrank_leaderboard?.[0]?.total_score || 0),
    },
    codeforcesData:{
        problems_solved: data.codeforcesData?.leaderboard?.[0]?.problems_solved || 0,
    },
    gfg:{
        problems_solved: data.Gfgprofile?.[0]?.allCount || 0,
        HardCount: data.Gfgprofile?.[0]?.HardCount || 0,
        MediumCount: data.Gfgprofile?.[0]?.MediumCount || 0,
        EasyCount: data.Gfgprofile?.[0]?.EasyCount || 0,
    }
  }

  // Get current date for report generation
  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  

  // Helper function to calculate HackerRank stars
  function calculateHackerRankStars(score) {
    if (score >= 200) return 5
    if (score >= 150) return 4.5
    if (score >= 100) return 4
    if (score >= 50) return 3.5
    if (score >= 30) return 3
    if (score >= 20) return 2.5
    if (score >= 10) return 2
    if (score >= 5) return 1.5
    if (score > 0) return 1
    return 0
  }

  return (
    <div className="container mx-auto py-8 px-6 max-w-[1000px] bg-white">
      {/* University Header */}
      <div className="mb-8 border-b border-gray-200 pb-6">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <div className="h-16 w-16 flex items-center justify-center bg-blue-50 rounded-full">
              <GraduationCap className="h-10 w-10 text-blue-700" />
            </div>
            <div>
              <h1 className="text-2xl font-bold text-slate-900">{student.universityName}</h1>
            </div>
          </div>
          <div className="text-right">
            <p className="text-sm text-slate-500">Generated for</p>
            <p className="font-medium text-slate-700">{student.universityName}</p>
            <p className="text-sm text-slate-500 mt-1">{currentDate}</p>
          </div>
        </div>
      </div>

      {/* Report Title */}
      <div className="mb-8 text-center">
        <h1 className="text-3xl font-bold text-slate-900">Student Performance Report</h1>
      </div>

      {/* Student Profile Header */}
      <div className="border border-blue-100 rounded-lg p-6 mb-8 bg-blue-50/30">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <Avatar className="h-20 w-20 border-2 border-blue-100">
              <AvatarImage src={student.avatar} alt={student.name} />
              <AvatarFallback className="bg-blue-100 text-blue-700 text-xl">
                {student.name
                  .split(" ")
                  .map((n) => n[0])
                  .join("")}
              </AvatarFallback>
            </Avatar>
            <div>
              <h2 className="text-2xl font-bold text-slate-900">{student.name}</h2>
              <p className="text-slate-600">{student.email}</p>
            </div>
          </div>
          <Badge
            variant="outline"
            className="border-blue-200 bg-blue-100 text-blue-700 px-4 py-2 rounded-full text-base"
          >
            Batch: {student.batch}
          </Badge>
        </div>
      </div>

      {/* Overview Section */}
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-slate-900 border-b pb-2 flex items-center gap-2">
          <span className="w-1.5 h-6 bg-blue-600 rounded-full inline-block"></span>
          Overview
        </h2>
        <div className="grid grid-cols-4 gap-4">
          <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
            <p className="text-sm text-slate-500">Test Assigned</p>
            <p className="text-2xl font-bold text-slate-900">{student.overview.testAssigned}</p>
          </div>
          <div className="bg-green-50 p-4 rounded-lg border border-green-100">
            <p className="text-sm text-slate-500">Completed</p>
            <p className="text-2xl font-bold text-slate-900">{student.overview.testcompleted}</p>
          </div>
          <div className="bg-purple-50 p-4 rounded-lg border border-purple-100">
            <p className="text-sm text-slate-500">Questions Attempted</p>
            <p className="text-2xl font-bold text-slate-900">{student.overview.questionsAttempted}</p>
          </div>
          <div className="bg-amber-50 p-4 rounded-lg border border-amber-100">
            <p className="text-sm text-slate-500">Time Spent</p>
            <div className="flex items-center gap-2">
              <p className="text-2xl font-bold text-slate-900">{student.overview.timeSpent}</p>
              {/* <Progress value={student.overview.progress} className="h-2 w-16" /> */}
            </div>
          </div>
        </div>      
      </div>
      <div className="container flex flex-col p-3 mx-auto border rounded">
      <div className="flex items-center font-semibold">
        Subject Level Accuracy
      </div>
      <div className="mt-4 bg-white rounded-md shadow-r1">
        <div className="mb-2 text-sm">
          This metric reflects candidate overall performance across all
          assessments taken, providing a summary of accuracy at the subject
          level
        </div>
        {subjectProgress && Object.keys(subjectProgress).length > 0 ? (
          Object.entries(subjectProgress).map(([subjectId, subject]) => (
            <div
              key={subjectId}
              className="flex items-center justify-between px-4 py-2 mb-2 rounded bg-gray-50"
            >
              <h3
                className="flex items-center text-sm font-medium text-gray-800 cursor-pointer"
                onClick={() => setSelectedSubject(subjectId)}
              >
                {subject?.name?.replace("_", " ")}
                <FaChevronRight className="w-3 h-3 ml-1" />
                <FaChevronRight className="w-3 h-3 -ml-2" />
              </h3>
              <div className="flex items-center w-1/2">
                <div className="relative w-full h-4 mr-2 overflow-hidden bg-gray-200 rounded-full">
                  <div
                    className={`${getProgressColor(
                      subject?.marksObtained,
                      subject?.maxMarks
                    )}  h-4 absolute left-0 top-0`}
                    style={{
                      width: `${
                        subject?.maxMarks > 0
                          ? (subject?.marksObtained / subject?.maxMarks) * 100
                          : 0
                      }%`,
                    }}
                  />
                </div>
                <span className="text-sm font-medium text-gray-800">
                  {subject?.maxMarks > 0
                    ? Math.round(
                        (subject?.marksObtained / subject?.maxMarks) * 100
                      )
                    : 0}
                  %
                </span>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600 text-md">No test attempts for user</p>
        )}
      </div>
      {selectedSubject && (
        <Modal
          isOpen={!!selectedSubject}
          onRequestClose={() => setSelectedSubject(null)}
        >
          <div className="flex flex-col ">
            <button
              className="self-end"
              onClick={(e) => {
                e.preventDefault();
                setSelectedSubject(null);
              }}
            >
              <MdClose className="w-6 h-6 text-gray-600" />
            </button>
          </div>
          <TopicProgress subject={subjectProgress[selectedSubject]} />
        </Modal>
      )}
    </div>

      {overallStats && (
        <div className="p-4 my-3 border rounded-lg bg-gradient-to-r from-blue-50 to-indigo-50">
          <h3 className="mb-3 font-medium text-gray-700 text-md">
            Overall Learning Progress
          </h3>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
            {/* Overall Completion Rate */}
            <div className="flex items-center p-3 space-x-3 bg-white rounded-md shadow-sm">
              <div className="p-2 bg-blue-100 rounded-full">
                <Award className="w-5 h-5 text-blue-600" />
              </div>
              <div>
                <p className="text-xs text-gray-500">Completion Rate</p>
                <p className="text-lg font-semibold">
                  {overallStats.overallPercentage}%
                </p>
              </div>
            </div>

            {/* Total Time Spent */}
            <div className="flex items-center p-3 space-x-3 bg-white rounded-md shadow-sm">
              <div className="p-2 bg-green-100 rounded-full">
                <Clock className="w-5 h-5 text-green-600" />
              </div>
              <div>
                <p className="text-xs text-gray-500">Total Time Spent</p>
                <p className="text-lg font-semibold">
                  {overallStats.formattedTimeSpent}
                </p>
              </div>
            </div>

            {/* Lessons Progress */}
            <div className="flex items-center p-3 space-x-3 bg-white rounded-md shadow-sm">
              <div className="p-2 bg-yellow-100 rounded-full">
                <BookOpen className="w-5 h-5 text-yellow-600" />
              </div>
              <div>
                <p className="text-xs text-gray-500">Lessons Completed</p>
                <p className="text-lg font-semibold">
                  {overallStats.totalCompletedLessons} /{" "}
                  {overallStats.totalLessons}
                </p>
              </div>
            </div>

            {/* Course Status */}
            <div className="flex items-center p-3 space-x-3 bg-white rounded-md shadow-sm">
              <div className="p-2 bg-indigo-100 rounded-full">
                <BookOpen className="w-5 h-5 text-indigo-600" />
              </div>
              <div>
                <p className="text-xs text-gray-500">Course Status</p>
                <p className="text-lg font-semibold">
                  {overallStats.completedCourses} / {overallStats.totalCourses}{" "}
                  Completed
                </p>
              </div>
            </div>
          </div>

          {/* Progress Bar */}
          <div className="mt-4">
            <div className="flex justify-between mb-1 text-xs text-gray-500">
              <span>Overall Progress</span>
              <span>{overallStats.overallPercentage}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className={`h-2.5 rounded-full ${
                  overallStats.overallPercentage >= 75
                    ? "bg-green-500"
                    : overallStats.overallPercentage >= 50
                    ? "bg-blue-500"
                    : overallStats.overallPercentage >= 25
                    ? "bg-yellow-500"
                    : "bg-red-500"
                }`}
                style={{ width: `${overallStats.overallPercentage}%` }}
              ></div>
            </div>
          </div>
        </div>
      )}

      {/* Assignments Section */}
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-slate-900 border-b pb-2 flex items-center gap-2">
          <span className="w-1.5 h-6 bg-blue-600 rounded-full inline-block"></span>
          Assessments
        </h2>
        <div className="space-y-4">
        {assessments.map((assessment) => (
            <div key={assessment.id} className="border border-gray-200 rounded-lg p-4 shadow-sm">
              <div className="space-y-3">
              <div>
                    <div className="flex items-center gap-2 mb-2">
                      <h3 className="font-semibold text-lg">{assessment.name}</h3>
                      {assessment.status === "completed" ? (
                        <Badge className="bg-green-100 text-green-700 border-green-200">
                          Completed
                        </Badge>
                      ) : (
                        <Badge className="bg-yellow-100 text-yellow-700 border-yellow-200">
                          Pending
                        </Badge>
                      )}
                    </div>
                    <div className="flex gap-4 text-sm text-slate-500">
                      <div className="flex items-center gap-1">
                        <CalendarDays className="h-4 w-4" />
                        <span>{assessment.submittedOn}</span>
                      </div>
                    </div>
                  </div>

                <div className="space-y-1">
                  <div className="flex justify-between text-sm">
                    <span>Percentage</span>
                    <span className="font-medium">{assessment.percentage}%</span>
                  </div>
                  <Progress
                    value={assessment.percentage}
                    className="h-2"
                    style={
                      {
                        backgroundColor: "#e2e8f0",
                        "--progress-color":
                        assessment.percentage < 30 ? "#ef4444" : assessment.percentage < 70 ? "#f59e0b" : "#22c55e",
                        "--progress-value": `${assessment.percentage}%`,
                      } 
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Course Progress Section */}
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-slate-900 border-b pb-2 flex items-center gap-2">
          <span className="w-1.5 h-6 bg-blue-600 rounded-full inline-block"></span>
          Course Progress
        </h2>
        <div className="space-y-4">
          {courses.map((course) => (
            <div key={course.id} className="border border-gray-200 rounded-lg p-4 shadow-sm">
              <div className="space-y-3">
                <div className="flex flex-col md:flex-row justify-between md:items-center gap-3">
                  <h3 className="font-semibold text-lg">{course.name}</h3>
                  <div className="flex gap-6">
                    <div>
                      <p className="text-sm text-slate-500">Total</p>
                      <p className="text-xl font-bold text-slate-900">{course.total}</p>
                    </div>
                    <div>
                      <p className="text-sm text-slate-500">Completed</p>
                      <p className="text-xl font-bold text-blue-600">{course.completed}</p>
                    </div>
                  </div>
                </div>

                <div className="space-y-1">
                  <div className="flex justify-between text-sm">
                    <span>Progress</span>
                    <span className="font-medium">{course.progress}%</span>
                  </div>
                  <Progress
                    value={course.progress}
                    className="h-2"
                    style={
                      {
                        backgroundColor: "#e2e8f0",
                        "--progress-color":
                          course.progress < 30 ? "#ef4444" : course.progress < 70 ? "#f59e0b" : "#22c55e",
                        "--progress-value": `${course.progress}%`,
                      } 
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Platform Performance Section */}
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4 text-slate-900 border-b pb-2 flex items-center gap-2">
          <span className="w-1.5 h-6 bg-blue-600 rounded-full inline-block"></span>
          Platform Performance
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {/* LeetCode */}
          <div className="border border-gray-200 rounded-lg p-4 shadow-sm">
            <h3 className="text-lg font-semibold mb-3 flex items-center gap-2">
              <Code className="h-5 w-5 text-blue-600" />
              LeetCode
            </h3>
            <div className="space-y-3">
              <div>
                <p className="text-sm text-slate-500">Problems Solved</p>
                <p className="text-2xl font-bold text-blue-600">{platformData.leetcode.problemsSolved}</p>
              </div>
              <div>
                <p className="text-sm text-slate-500 mb-1">Difficulty Breakdown</p>
                <div className="grid grid-cols-3 gap-2 text-sm">
                  <div className="bg-green-50 p-2 rounded text-center border border-green-100">
                    <p className="text-green-600 font-medium">Easy: {platformData.leetcode.easy}</p>
                  </div>
                  <div className="bg-amber-50 p-2 rounded text-center border border-amber-100">
                    <p className="text-amber-600 font-medium">Medium: {platformData.leetcode.medium}</p>
                  </div>
                  <div className="bg-red-50 p-2 rounded text-center border border-red-100">
                    <p className="text-red-600 font-medium">Hard: {platformData.leetcode.hard}</p>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-sm text-slate-500">Badges Earned</p>
                <p className="text-lg font-bold text-slate-900">{platformData.leetcode.badges}</p>
              </div>
            </div>
          </div>

          {/* HackerRank */}
          <div className="border border-gray-200 rounded-lg p-4 shadow-sm">
            <h3 className="text-lg font-semibold mb-3 flex items-center gap-2">
              <Award className="h-5 w-5 text-blue-600" />
              HackerRank
            </h3>
            <div className="space-y-3">
              <div>
                <p className="text-sm text-slate-500">Total Score</p>
                <p className="text-2xl font-bold text-blue-600">{platformData.hackerrank.total_score}</p>
              </div>
              <div>
                <p className="text-sm text-slate-500 mb-1">Stars</p>
                <div className="flex">
                  {[...Array(Math.floor(platformData.hackerrank.stars))].map((_, i) => (
                    <Star key={i} className="h-5 w-5 fill-amber-400 text-amber-400" />
                  ))}
                  {platformData.hackerrank.stars % 1 !== 0 && (
                    <StarHalf className="h-5 w-5 fill-amber-400 text-amber-400" />
                  )}
                </div>
              </div>
              
            </div>
          </div>

          {/* GFG */}
          <div className="border border-gray-200 rounded-lg p-4 shadow-sm">
            <h3 className="text-lg font-semibold mb-3 flex items-center gap-2">
              <Code className="h-5 w-5 text-blue-600" />
              GeeksForGeeks
            </h3>
            <div className="space-y-3">
              <div>
                <p className="text-sm text-slate-500">Problems Solved</p>
                <p className="text-2xl font-bold text-blue-600">{platformData.gfg.problems_solved}</p>
              </div>
              <div>
                <p className="text-sm text-slate-500 mb-1">Difficulty Breakdown</p>
                <div className="grid grid-cols-3 gap-2 text-sm">
                  <div className="bg-green-50 p-2 rounded text-center border border-green-100">
                    <p className="text-green-600 font-medium">Easy: {platformData.gfg.EasyCount}</p>
                  </div>
                  <div className="bg-amber-50 p-2 rounded text-center border border-amber-100">
                    <p className="text-amber-600 font-medium">Medium: {platformData.gfg.MediumCount}</p>
                  </div>
                  <div className="bg-red-50 p-2 rounded text-center border border-red-100">
                    <p className="text-red-600 font-medium">Hard: {platformData.gfg.HardCount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* CF */}
          <div className="border border-gray-200 rounded-lg p-4 shadow-sm">
            <h3 className="text-lg font-semibold mb-3 flex items-center gap-2">
              <Code className="h-5 w-5 text-blue-600" />
              Code Forces
            </h3>
            <div className="space-y-3">
              <div>
                <p className="text-sm text-slate-500">Problems Solved</p>
                <p className="text-2xl font-bold text-blue-600">{platformData.codeforcesData.problems_solved}</p>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-12 pt-4 border-t border-gray-200 text-center text-sm text-slate-500">
        <p>This report is generated by the {student.universityName} Learning Management System.</p>
        <p className="mt-1">For any queries, please contact the department.</p>
        <p className="mt-4 text-xs">
          © {new Date().getFullYear()} {student.universityName}. All rights reserved.
        </p>
      </div>
    </div>
  )
}
