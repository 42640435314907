import * as Sentry from "@sentry/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { getPublicRoutes } from "./PublicRoutes";
import { Suspense, lazy } from "react";
import { lazyWithRetry } from "@/common/utils/lazyWithRetry";
import { useAuth } from "@/features/auth/hooks/useAuth";
import FallbackLoader from "@/common/components/shared/FallbackLoader";
import ErrorBoundary from "@/common/components/shared/ErrorBoundary";
import RouteTracker from "./RouteTracker";
const BuilderPage = lazyWithRetry(() =>
  import("@/pages/ResumeBuilder/ResumeBuilder")
);
import DialogProvider from "@/features/resume-builder/components/dialog-provider";

const Jobs = lazy(() => import("@/pages/jobs"));

const ProjectAssignmentDetails = lazy(() =>
  import("@/pages/project_assignments_details")
);

const Contests = lazyWithRetry(() => import("@/pages/contests"));
const Sheets = lazyWithRetry(() => import("@/pages/sheets"));
const Schedule = lazyWithRetry(() => import("@/pages/schedule"));
const IDE = lazyWithRetry(() => import("@/pages/ide"));
const Test = lazyWithRetry(() => import("@/pages/test"));
const Tests = lazyWithRetry(() => import("@/pages/tests"));
const Course = lazyWithRetry(() => import("@/pages/course"));
const Courses = lazyWithRetry(() => import("@/pages/courses"));
const Support = lazyWithRetry(() => import("@/pages/support"));
const Sandbox = lazyWithRetry(() => import("@/pages/sandbox"));
const Profile = lazyWithRetry(() => import("@/pages/profile"));
const Settings = lazyWithRetry(() => import("@/pages/settings"));
const Dashboard = lazyWithRetry(() => import("@/pages/dashboard"));
const Challenges = lazyWithRetry(() => import("@/pages/challenges"));
const Communities = lazyWithRetry(() => import("@/pages/communities"));
const AiInterview = lazyWithRetry(() => import("@/pages/ai_interview"));
const LearningPath = lazyWithRetry(() => import("@/pages/learning_path"));
const ProgramStats = lazyWithRetry(() => import("@/pages/program_stats"));
const ExpertConnect = lazyWithRetry(() => import("@/pages/expert_connect"));
const TestChallenge = lazyWithRetry(() => import("@/pages/test_challenge"));
const TestChallenges = lazyWithRetry(() => import("@/pages/test_challenges"));
const ProjectAssignments = lazyWithRetry(() =>
  import("@/pages/project_assignments")
);
const Overall = lazyWithRetry(() =>
  import("@/features/program_stats/components/overall")
);
const Leetcode = lazyWithRetry(() =>
  import("@/features/program_stats/components/leetcode")
);
const Hackerrank = lazyWithRetry(() =>
  import("@/features/program_stats/components/hackerrank")
);
const GeeksForGeeks = lazyWithRetry(() =>
  import("@/features/program_stats/components/GeeksForGeeks")
);
const LiveSession = lazyWithRetry(() =>
  import("@/pages/live_session/index")
);

// Create a Sentry-wrapped Router
const SentryRouter = Sentry.withProfiler(Router);
// Create a Sentry-wrapped Routes component
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export function AppRoutes({ university }) {
  const { user, loading } = useAuth();
  console.log("user, loading: ", user, loading);

  // Get public routes as an array of route configs
  const publicRoutes = getPublicRoutes(user, university);

  const protectedRoutes = [
    {
      path: "",
      element: <Navigate to="dashboard" replace />,
    },
    {
      path: "dashboard",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Dashboard />,
        </Suspense>
      ),
    },
    // {
    //   path: "course/:course_id/:participant_id/*",
    //   element: (
    //     <Suspense fallback={<FallbackLoader />}>
    //       <ViewCourse />
    //     </Suspense>
    //   ),
    // },
    {
      path: "resume_builder",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <DialogProvider>
            <div className="box-border h-screen max-h-screen">
              <BuilderPage />
            </div>
          </DialogProvider>
        </Suspense>
      ),
    },
    {
      path: "project_assignments/*",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <ProjectAssignments />
        </Suspense>
      ),
      condition: user?.profile?.university?.config.projects_assign,
    },
    {
      path: "project_assignments/:project_id/*",
      element: <ProjectAssignmentDetails />,
      condition: user?.profile?.university?.config.projects_assign,
    },
    {
      path: "communities/*",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Communities />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.communities,
    },
    {
      path: "challenges/*",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Challenges />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.challenges,
    },
    {
      path: "ide",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <IDE />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.ide,
    },
    {
      path: "AiInterview",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <AiInterview />
        </Suspense>
      ),
      condition: !user?.profile?.university?.config?.AiInterview,
    },
    {
      path: "courses",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Courses />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.courses,
    },
    {
      path: "sandbox",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Sandbox />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.sandbox,
    },
    {
      path: "expert-connect",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <ExpertConnect />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.expert_connect,
    },
    {
      path: "support",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Support />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.support_page,
    },
    {
      path: "learning-path/*",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <LearningPath />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.courses,
    },
    {
      path: "live-session/*",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <LiveSession />
        </Suspense>
      ),
      condition:user?.profile?.university?.config?.live_session,

    },
    {
      path: "course/:course_id/*",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Course />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.courses,
    },
    {
      path: "sheets/*",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Sheets />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.courses,
    },
    {
      path: "events",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <div>Events</div>
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.events,
    },
    {
      path: "schedule",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Schedule />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.schedule,
    },
    {
      path: "event/:event_id",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <div>Event with id</div>
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.events,
    },
    {
      path: "stats/:type",
      element: (
        <Navigate
          from={`stats/:type`}
          exact
          to={`stats/:type/${user?.profile?.university_id}`}
        />
      ),
      condition: user?.profile?.university?.config?.explore,
    },
    {
      path: "projects",
      element: (
        <Navigate
          from={`projects`}
          exact
          to={`projects/${user?.profile?.university_id}?page=1`}
        />
      ),
      condition: user?.profile?.university?.config?.projects_assign,
    },
    {
      path: "projects/:university_id",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <div>Projects</div>
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.projects_assign,
    },
    {
      path: "hackerrank/leaderboard",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <div>Hackerrank</div>
        </Suspense>
      ),
      condition:
        user?.profile?.university?.config?.hackerrank &&
        user?.profile?.university?.config?.hackerrank_overall_leaderboard,
    },
    {
      path: "hackerrank/:test_challenge_id/:contest_slug",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <TestChallenge />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.hackerrank,
    },
    {
      path: "tests/*",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Tests />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.test,
    },
    {
      path: "contests/*",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Contests />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.test,
    },
    {
      path: "test_challenges",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <TestChallenges />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.hackerrank,
    },
    {
      path: "test/:test_id/*",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Test />
        </Suspense>
      ),
      condition: user?.profile?.university?.config?.test,
    },
    {
      path: "jobs/*",
      element: <Jobs />,
      condition: user?.profile?.university?.config?.jobs,
    },
    {
      path: "profile",
      element: (
        <Navigate
          path={`profile`}
          to={user?.profile ? `${user?.profile.id}` : `/`}
          exact
        />
      ),
    },
    {
      path: "profile/:id",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Profile />
        </Suspense>
      ),
    },
    {
      path: "edit/profile",
      element: (
        <Navigate path={`edit`} to={`/home/edit/profile/aboutyou`} exact />
      ),
    },
    {
      path: "edit",
      element: (
        <Navigate path={`edit`} to={`/home/edit/profile/aboutyou`} exact />
      ),
    },
    {
      path: "edit/:type/*",
      element: (
        <Suspense fallback={<FallbackLoader />}>
          <Settings />
        </Suspense>
      ),
    },
  ];

  if (loading) return <FallbackLoader />;

  return (
    <SentryRouter>
      <ErrorBoundary>
        <Suspense fallback={<FallbackLoader message="Routes Loading" />}>
          <RouteTracker />
          <SentryRoutes>
            {/* Render public routes */}
            {publicRoutes.map((route, index) => (
              <Route
                key={route.path || index}
                path={route.path}
                element={route.element}
              />
            ))}

            {/* Protected routes */}
            <Route path="/home" element={<ProtectedRoute />}>
              <Route path="program_stats" element={<ProgramStats />}>
                {/* Program stats subroutes */}
                <Route index element={<Overall />} />
                <Route path={`leetcode`} element={<Leetcode />} />
                <Route path={`hackerrank`} element={<Hackerrank />} />
                <Route path={`geeksforgeeks`} element={<GeeksForGeeks />} />
              </Route>

              {/* Render protected routes */}
              {protectedRoutes.map(
                (route) =>
                  (!route.condition || route.condition) && (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={route.element}
                    />
                  )
              )}
            </Route>

            {/* Catch all route */}
            <Route path="*" element={<Navigate to="/home" />} />
          </SentryRoutes>
        </Suspense>
      </ErrorBoundary>
    </SentryRouter>
  );
}
